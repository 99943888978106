
import Vue from "vue";
import { Actions } from "@/store/models";
import {
  AssociateRoles,
  associates,
  companies,
  INlcVerifyUserEmailResponse,
} from "@/api";
import Logo from "@/components/common/Logo.vue";
import { getAvatarColor } from "@/utils";

export default Vue.extend({
  name: "SignupInviteMember",
  components: { Logo },
  data() {
    return {
      image: require("/src/assets/sidebar-bkground.png"),
      invites: [{ exists: false, email: "" }],
    };
  },
  computed: {},
  methods: {
    skip() {
      this.$router.push("/signup/navigate");
    },
    add() {
      this.invites.push({
        exists: false,
        email: "",
      });
    },
    remove(i: number) {
      if (this.invites.length > 1) {
        this.invites.splice(i, 1);
      }
    },
    async submit() {
      // const verifyApiCalls: any = [];

      // this.invites.forEach((invite) => {
      //   verifyApiCalls.push(companies.nlc.verifyUserEmail(invite.email));
      // });
      // const responses = await Promise.all(verifyApiCalls);

      // let canProceed = true;
      // responses.forEach((response, i) => {
      //   this.invites[i].exists = (
      //     response as INlcVerifyUserEmailResponse
      //   ).data.userExists;
      //   if (this.invites[i].exists) {
      //     canProceed = false;
      //   }
      // });

      // if (!canProceed) {
      //   return;
      // }

      const addApiCalls: any = [];
      this.invites.forEach((invite) => {
        addApiCalls.push(
          associates.invite({
            user_name: invite.email,
            roles: [
              AssociateRoles.ManageProjects,
              AssociateRoles.ManageReports,
            ],
            company: { id: this.$store.state.associate.company.id },
            associate_groups: [],
            profile_bg_color: getAvatarColor(),
          })
        );
      });

      try {
        await Promise.all(addApiCalls);
        this.$router.push("/signup/navigate");
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
